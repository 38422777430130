/* 3.2 couple-section */

.couple-section,
.couple-section-s2 {
	padding-bottom: 50px;
	position: relative;
	z-index: 1;
	overflow: hidden;

	.shape-1 {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		z-index: -1;

		@media(max-width:767px) {
			display: none;
		}

		img {
			width: 100%;

			@media(max-width:1700px) {
				width: 70%;
			}

			@media(max-width:1400px) {
				width: 60%;
			}

			@media(max-width:1200px) {
				width: 40%;
			}
		}
	}

	.shape-2 {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		z-index: -1;
		text-align: right;

		img {
			width: 100%;

			@media(max-width:1700px) {
				width: 70%;
			}

			@media(max-width:1400px) {
				width: 60%;
			}

			@media(max-width:1200px) {
				width: 40%;
			}
		}

		@media(max-width:767px) {
			display: none;
		}
	}

	@media(max-width:991px) {
		padding-bottom: 30px;
	}

	.couple-area {
		.couple-item {
			margin-bottom: 60px;

			@media(max-width:991px) {
				text-align: center;
			}

			.couple-img {
				padding: 20px;
				background-color: $white;
				box-shadow: 0px 0px 6.1px 3.9px rgba(28, 31, 86, 0.06);
				border-radius: 50%;
				overflow: hidden;

				@media(max-width:991px) {
					max-width: 450px;
					margin: 0 auto;
					margin-bottom: 30px;
				}

				img {
					border-radius: 50%;
					width: 100%;
				}

				.couple-shape {
					display: none;
				}

			}

			.couple-text {
				padding-left: 30px;

				@media(max-width:991px) {
					padding-left: 0;
				}

				h3 {
					font-weight: 400;
					font-size: 50px;
					font-size: calc-rem-value(50);
					line-height: 79px;
					margin: 0 0 0.3em;
					font-weight: 500;

					@media(max-width:575px) {
						font-size: 40px;
						font-size: calc-rem-value(40);
					}
				}

				p {
					margin-bottom: 10px;
				}

				ul {
					list-style: none;
					overflow: hidden;
					padding-top: 15px;
					display: flex;

					@include media-query(991px) {
						padding-top: 10px;
						display: flex;
						justify-content: center;
					}

					>li+li {
						margin-left: 15px;
					}

					li a {
						display: block;
						text-align: center;
						color: $theme-primary-color;
						width: 40px;
						height: 40px;
						line-height: 40px;
						text-align: center;
						border: 1px solid $theme-primary-color;
						border-radius: 50%;


						&:hover {
							color: $white;
							background: $theme-primary-color;
						}
					}
				}
			}

			&.groom {
				.row {
					justify-content: end;
				}

				.couple-text {
					text-align: right;
					padding-right: 30px;
					padding-left: 0;

					@media(max-width:991px) {
						text-align: center;
						padding-right: 0;
					}

					ul {
						justify-content: end;

						@media(max-width:991px) {
							justify-content: center;
						}
					}
				}
			}
		}
	}
}


/* 3.3 wpo-video-section*/

.wpo-video-section {
	position: relative;
	z-index: 1;
	// background: url(../../images/cta.jpg) no-repeat top center;
	height: 500px;
	background-attachment: fixed;


	.container {
		@media(min-width:1600px) {
			max-width: 1400px;
		}
	}

	.video-btn button.wrap {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 80px;
		height: 80px;
		border: 2px solid $white;
		text-align: center;
		border-radius: 50%;
		line-height: 76px;
		background: $white;
		border: 0;

		@include media-query(575px) {
			width: 50px;
			height: 50px;
			line-height: 47px;
		}

		.fi {
			position: relative;
			right: -3px;

			@include media-query(575px) {
				right: 0;
			}

			&:before {
				font-size: 30px;
				color: $theme-primary-color;

				@include media-query(575px) {
					font-size: 20px;
				}
			}
		}
	}
}

/* 3.4 wpo-story-section */

.story-section {
	position: relative;
	z-index: 1;

	@media(max-width:767px) {
		overflow: hidden;
	}

	.shape-1 {
		position: absolute;
		left: 0;
		top: 5%;
		z-index: -1;
		min-height: 100%;

		.sticky-shape {
			position: -webkit-sticky;
			position: sticky;
			top: 50px;
		}

		@media(max-width:767px) {
			display: none;
		}

		img {
			width: 100%;

			@media(max-width:1700px) {
				width: 70%;
			}

			@media(max-width:1400px) {
				width: 60%;
			}

			@media(max-width:1200px) {
				width: 40%;
			}
		}
	}

	.shape-2 {
		position: absolute;
		right: 0;
		top: 5%;
		z-index: -1;
		text-align: right;
		min-height: 100%;

		.sticky-shape {
			position: -webkit-sticky;
			position: sticky;
			top: 50px;
		}

		img {
			width: 100%;

			@media(max-width:1700px) {
				width: 70%;
			}

			@media(max-width:1400px) {
				width: 60%;
			}

			@media(max-width:1200px) {
				width: 40%;
			}
		}

		@media(max-width:767px) {
			display: none;
		}
	}

	.story-timeline {
		position: relative;

		.heart {
			background-color: $white;
			width: 70px;
			height: 70px;
			line-height: 71px;
			text-align: center;
			position: absolute;
			left: -35px;
			top: -22px;
			border: 1px solid transparentize($theme-primary-color-s2, .5);
			@include rounded-border();
			z-index: 10;

			@include media-query(991px) {
				display: none;
			}
		}

		.fi {
			color: $theme-primary-color;
			font-size: 40px;
		}

		&:after {
			content: "";
			background: transparentize($theme-primary-color-s2, .5);
			width: 2px;
			height: 100%;
			position: absolute;
			left: 50%;
			top: 0;
			@include translatingX();

			@include media-query(991px) {
				display: none;
			}
		}

		.story-timeline-item {
			padding-top: 270px;

			@media(max-width:991px) {
				padding-top: 50px;
				text-align: center;
			}

			&.s1 {
				padding-top: 150px;

				@media(max-width:991px) {
					padding-top: 0px;
				}
			}
		}


		.img-holder {
			position: relative;
			z-index: 1;
			margin: 0 auto;

			.heart {
				top: -170px;
				left: 15px;
			}

			@media(max-width:991px) {
				margin-bottom: 40px;
			}
		}
	}

	.story-timeline>.row {
		position: relative;

		.horizontal-line {
			background: transparentize($theme-primary-color, 0.9);
			width: 70%;
			height: 1px;
			position: absolute;
			left: 50%;
			top: 0;
			@include translatingX();
		}

		&:last-child {
			.col {
				padding-top: 0 !important;
			}

			@media(max-width:991px) {
				padding: 0 !important;
				margin: 0 !important;
			}
		}
	}

	.story-timeline>.row+.row {
		margin-top: 50px;
		padding-top: 15px;

		@include media-query(991px) {
			padding-top: 30;
		}
	}

	.story-timeline>.row>.col:first-child {
		padding: 0 45px 0 100px;

		@include media-query(1199px) {
			padding: 0 45px 0 70px;
		}

		@include media-query(991px) {
			padding: 0 15px;
		}
	}

	.story-timeline>.row>.col:last-child {
		padding: 0 100px 0 45px;

		@include media-query(1199px) {
			padding: 0 70px 0 45px;
		}

		@include media-query(991px) {
			padding: 0 15px;
		}
	}

	.story-timeline .story-text {
		padding: 50px;
		border: 10px solid $theme-primary-color-s2;
		max-width: 510px;
		position: relative;
		z-index: 11;
		background: $white;

		@include media-query(991px) {
			background: transparentize($theme-primary-color, 0.95);
			padding: 35px 25px;
			text-align: center;
			margin: 0 auto;
		}

	}

	.story-timeline .left-site .story-text {
		position: relative;
		z-index: 11;

		@include media-query(991px) {
			background: transparentize($theme-primary-color, 0.95);
			padding: 35px 25px;
			text-align: center;
		}

	}

	.story-timeline .right-align-text {
		@include widther(992px) {
			text-align: right;
			margin-right: -60px;
		}
	}

	.story-timeline .left-align-text {
		@include widther(992px) {
			text-align: left;
			margin-left: -60px;
		}
	}

	.story-timeline .text-holder {
		position: relative;

		&.left-text {
			.story-text {
				margin-left: auto;
				margin-right: -50px;

				@include media-query(991px) {
					margin: 0 auto;
				}
			}
		}

		@include media-query(991px) {
			border: 0;
		}

	}

	.story-timeline .right-heart .heart {
		left: auto;
		right: 10px;
	}

	.story-timeline h3 {
		font-size: 35px;
		font-size: calc-rem-value(35);
		font-weight: 500;
		color: $dark-gray;
		margin: 0 0 0.3em;

		@include media-query(991px) {
			font-size: 30px;
			font-size: calc-rem-value(30);
		}

		@include media-query(767px) {
			font-size: 25px;
			font-size: calc-rem-value(25);
		}
	}

	.story-timeline .date {
		font-size: 20px;
		font-size: calc-rem-value(20);
		font-weight: 400;
		display: block;
		margin-bottom: 15px;
		color: $dark-gray;

		@include media-query(767px) {
			font-size: 16px;
			font-size: calc-rem-value(16);
		}
	}

	.story-timeline .line-shape {
		position: relative;
		margin: 10px 0 15px;

		@include media-query(991px) {
			max-width: 100px;
			margin: 0 auto;
			margin-top: 10px;
			margin-bottom: 15px;
		}

		&:before {
			position: absolute;
			left: 19px;
			top: 7px;
			width: 100px;
			height: 1px;
			content: "";
			background: $theme-primary-color;
		}

		&:after {
			position: absolute;
			left: 19px;
			top: 12px;
			width: 120px;
			height: 1px;
			content: "";
			background: $theme-primary-color;
		}

		.outer-ball {
			width: 20px;
			height: 20px;
			border: 1px solid $theme-primary-color;
			border-radius: 50%;
			position: relative;

			.inner-ball {
				position: absolute;
				left: 5px;
				top: 5px;
				width: 8px;
				height: 8px;
				background: $theme-primary-color;
				border-radius: 50%;
			}
		}

		&.s2 {
			text-align: right;

			&:before {
				left: auto;
				right: 19px;
			}

			&:after {
				left: auto;
				right: 19px;
			}

			.outer-ball {
				margin-left: auto;
			}
		}
	}

	.story-timeline p {
		margin-bottom: 0;
		font-size: 20px;

		@include media-query(767px) {
			font-size: 16px;
			font-size: calc-rem-value(16);
		}
	}

	.story-timeline img {
		display: inline-block;
	}

}


/* 3.5 wpo-cta-section */

.wpo-cta-section,
.wpo-cta-section-s2,
.wpo-cta-section-s3 {
	padding: 145px 0;
	background: url(../../images/cta2.jpg) no-repeat center center;
	position: relative;
	z-index: 1;

	@media(max-width:991px) {
		padding: 90px 0;
	}

	&:before {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: $black;
		content: "";
		opacity: .35;
		z-index: -1;
	}

	.wpo-cta-item {
		text-align: center;

		h2 {
			font-size: 75px;
			color: $white;
			margin: 10px 0 30px;

			@media(max-width:991px) {
				font-size: 60px;
			}

			@media(max-width:767px) {
				font-size: 40px;
			}

			@media(max-width:480px) {
				font-size: 30px;
				margin: 10px 0 20px;
			}
		}

		span {
			display: block;
			position: relative;
			left: -10px;
		}
	}
}



/* 3.6 wpo-portfolio-section*/

.wpo-portfolio-section,
.wpo-portfolio-section-s2 {
	overflow: hidden;

	.portfolio-grids {
		margin: 0 -7.5px;

		.grid {
			width: 33.33%;
			float: left;
			padding: 0 7.5px 15px;

			@include media-query(1500px) {
				padding: 0 5.5px 10px;
			}

			@include media-query(991px) {
				padding: 0 4.5px 8px;
			}

			@include media-query(600px) {
				width: 100%;
			}

			img {
				width: 100%;
				max-width: 100%;
			}

			&:nth-child(4) {
				width: 50%;

				@include media-query(600px) {
					width: 100%;
				}
			}

			&:nth-child(5) {
				width: 50%;

				@include media-query(600px) {
					width: 100%;
				}
			}
		}
	}

	.grid {
		.img-holder {
			position: relative;

			.react-fancybox {
				.thumbnail {
					&:before {
						position: absolute;
						left: 2%;
						top: 2%;
						width: 96%;
						height: 96%;
						content: "\e61a";
						background: rgba(255, 255, 255, 0.8);
						opacity: 0;
						transition: all .3s;
						transform: scale(0);
						font-family: 'themify';
						display: flex;
						justify-content: center;
						flex-direction: column;
						text-align: center;
						font-size: 30px;
						color: #002642;
						cursor: pointer;
					}

					&:hover {
						&:before {
							opacity: 1;
							transform: scale(1);
						}
					}

					img {
						max-width: 100%;
					}

				}

				.box {
					z-index: 99999999;
				}
			}

			.close-btn {
				width: unset;
				cursor: pointer;
			}
		}
	}

	.img-holder:hover {
		&:before {
			opacity: 1;
			transform: scale(1);
		}

	}

}


/* 3.7 wpo-contact-section */

.wpo-contact-section,
.wpo-contact-section-s2 {
	padding-bottom: 200px;
	position: relative;
	z-index: 1;
	padding-top: 90px;
	overflow: hidden;


	&.pt-200 {
		padding-top: 200px;

		@media(max-width:575px) {
			padding-top: 80px;
		}
	}

	@media(max-width:991px) {
		padding-bottom: 180px;
	}

	@media(max-width:767px) {
		padding-bottom: 140px;
	}

	@media(max-width:575px) {
		padding-bottom: 80px;
		padding-top: 10px;
	}

	.wpo-contact-section-wrapper {
		position: relative;
		max-width: 830px;
		margin: 0 auto;
		box-shadow: 0px 4px 8px 2px rgba(0, 11, 50, 0.25);
		background: $white;
		border-radius: 322px;

		@media(max-width:1200px) {
			max-width: 570px;
		}

		.wpo-section-title {
			@media(max-width:1200px) {
				margin-bottom: 20px;
			}
		}

		.vector-1 {
			position: absolute;
			left: -100px;
			top: -125px;
			z-index: 1;
			

			@media(max-width:1200px) {
				left: -85px;
			}

			@media(max-width:767px) {
				left: -100px;
			}

			img {
				max-width: unset;
			}

			@media(max-width:575px) {
				display: none;
			}
		}

		.vector-2 {
			position: absolute;
			right: -100px;
			bottom: -125px;
			z-index: 1;

			@media(max-width:1200px) {
				right: -85px;
			}

			@media(max-width:767px) {
				right: -100px;
			}

			@media(max-width:575px) {
				display: none;
			}

			img {
				max-width: unset;
			}
		}

		.wpo-contact-form-area {
			padding: 130px 50px;
			position: relative;
			z-index: 99;
			border-radius: 322px;

			@media(max-width:575px) {
				padding: 50px;
			}

			@media(max-width:450px) {
				padding: 50px 30px;
			}

			.wpo-section-title {
				margin-bottom: 20px;
			}

			.form-field {
				margin-bottom: 20px;
			}

			.errorMessage {
				font-size: 16px;
				color: red;
			}

			.form-control {
				width: 100%;
				height: 50px;
				border: 0;
				border-bottom: 1px solid #D9D9D9;
				background: transparent;
				border-radius: 0;
				color: #787878;
				padding-left: 0;
				font-size: 18px;
				margin-bottom: 3px;

				&:focus {
					outline: none;
					box-shadow: none;
				}
			}

			.form-control::-webkit-input-placeholder {
				/* Edge */
				color: #787878;
			}

			.form-control:-ms-input-placeholder {
				/* Internet Explorer 10-11 */
				color: #787878;
			}

			.form-control::placeholder {
				color: #787878;
			}


			select.form-control {
				color: #6c757d;
				-webkit-appearance: none;
				-ms-appearance: none;
				-o-appearance: none;
				appearance: none;
				-moz-appearance: none;
				background: transparent url(../../images/select-icon2.png) no-repeat calc(100% - 15px) center;
				position: relative;
			}


			.select.last {
				margin-bottom: 50px;
			}

			.submit-area {
				margin-top: 40px;
				text-align: center;
			}
		}
	}

	.radio-buttons {
		display: flex;

		@media(max-width:575px) {
			display: block;
		}

		p {
			&+p {
				margin-left: 30px;

				@media(max-width:575px) {
					margin-left: 0px;
				}
			}
		}

		[type="radio"]:checked,
		[type="radio"]:not(:checked) {
			position: absolute;
			left: -9999px;
		}

		[type="radio"]:checked+label,
		[type="radio"]:not(:checked)+label {
			position: relative;
			padding-left: 28px;
			cursor: pointer;
			line-height: 20px;
			display: inline-block;
			color: #666;
		}

		[type="radio"]:checked+label:before,
		[type="radio"]:not(:checked)+label:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 18px;
			height: 18px;
			border: 1px solid #ddd;
			border-radius: 100%;
			background: #fff;
		}

		[type="radio"]:checked+label:after,
		[type="radio"]:not(:checked)+label:after {
			content: '';
			width: 12px;
			height: 12px;
			background: $theme-primary-color;
			position: absolute;
			top: 3px;
			left: 3px;
			border-radius: 100%;
			-webkit-transition: all 0.2s ease;
			transition: all 0.2s ease;
		}

		[type="radio"]:not(:checked)+label:after {
			opacity: 0;
			-webkit-transform: scale(0);
			transform: scale(0);
		}

		[type="radio"]:checked+label:after {
			opacity: 1;
			-webkit-transform: scale(1);
			transform: scale(1);
		}
	}

	.shape-1 {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		z-index: -1;

		@media(max-width:767px) {
			display: none;
		}

		img {
			width: 100%;

			@media(max-width:1700px) {
				width: 70%;
			}

			@media(max-width:1400px) {
				width: 60%;
			}

			@media(max-width:1200px) {
				width: 40%;
			}
		}
	}

	.shape-2 {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		z-index: -1;
		text-align: right;

		img {
			width: 100%;

			@media(max-width:1700px) {
				width: 70%;
			}

			@media(max-width:1400px) {
				width: 60%;
			}

			@media(max-width:1200px) {
				width: 40%;
			}
		}

		@media(max-width:767px) {
			display: none;
		}
	}

}


/* 3.8 wpo-team-section */

.wpo-team-section {
	padding-bottom: 20px;
	overflow: hidden;

	@media(max-width:991px) {
		padding-bottom: 70px;
	}

	@media(max-width:575px) {
		padding-bottom: 50px;
	}

	.wpo-team-wrap {

		.row {
			margin: 0 -30px;
		}

		.col {
			padding: 0 30px;
		}

		.wpo-team-item {
			text-align: center;
			margin-bottom: 100px;

			@media(max-width:767px) {
				margin-bottom: 50px;
			}

			.wpo-team-img {
				margin: 0 auto;
				position: relative;

				@media(max-width:767px) {
					max-width: 400px;
				}

				.wpo-team-img-inner {
					border-radius: 50%;
					z-index: 1;
					background: $white;
					padding: 20px;
					box-shadow: 0px 0px 6.1px 3.9px rgba(28, 31, 86, 0.04);
				}

				img {
					border-radius: 50%;
				}

				.shape-1 {
					position: absolute;
					left: -60px;
					top: -60px;
					z-index: -1;
					transition: all .3s;
				}

				.shape-2 {
					position: absolute;
					right: -60px;
					bottom: -60px;
					z-index: -1;
					transition: all .3s;
				}
			}

			&:hover {
				.wpo-team-img {
					.shape-1 {
						left: -80px;
						top: -80px;
					}

					.shape-2 {
						right: -80px;
						bottom: -80px;
					}
				}
			}

			.wpo-team-text {
				padding-top: 20px;

				h3 {
					font-weight: 400;
					font-size: 35px;
					line-height: 55px;

					a {
						color: $dark-gray;

						&:hover {
							color: $theme-primary-color;
						}
					}
				}

				span {
					font-size: 16px;
					color: $theme-primary-color;
				}

				ul {
					list-style: none;
					display: flex;
					justify-content: center;
					margin-top: 20px;

					li {
						a {
							color: #002642;
							width: 42px;
							height: 42px;
							line-height: 42px;
							background: $section-bg-color;
							display: block;
							text-align: center;
							border-radius: 50%;
							font-size: 18px;

							i {
								font-size: 15px;
							}

							&:hover {
								background: $theme-primary-color-s2;
								color: $white;
							}
						}
					}

					li+li {
						margin-left: 10px;
					}
				}
			}
		}
	}
}


/* 3.9 wpo-event-section */

.wpo-event-section,
.wpo-event-section-s2 {
	padding-bottom: 90px;
	position: relative;
	z-index: 1;

	@media(max-width:767px) {
		padding-bottom: 70px;
	}

	&:before {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 430px;
		content: "";
		background: url(../../images/event/bg.jpg) no-repeat center center;
		z-index: -1;
	}

	&:after {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 430px;
		content: "";
		background: transparentize(#C59097, .2);
		z-index: -1;
	}


	.wpo-event-wrap {
		.wpo-event-item {
			position: relative;
			margin-bottom: 40px;
			box-shadow: 0px 0px 6.1px 3.9px rgba(28, 31, 86, 0.06);
			background: $white;
			padding: 30px;
			padding-top: 0;

			@media(max-width:1400px) {
				padding: 15px;
			}

			.wpo-event-text {
				padding: 40px 35px;
				text-align: center;
				padding-bottom: 15px;

				@media(max-width:1400px) {
					padding: 40px 0px 20px;
				}

				h2 {
					font-weight: 400;
					font-size: 35px;
					line-height: 55px;
					margin-bottom: 10px;
					position: relative;
					padding-bottom: 40px;
					display: block;

					&::before {
						position: absolute;
						left: 50%;
						transform: translateX(-50%);
						bottom: 0;
						content: "";
						background: url(../../images/event/shape.svg) no-repeat center center;
						width: 100%;
						height: 40px;
					}
				}

				ul {
					list-style: none;

					li {
						margin-bottom: 15px;
						font-size: 18px;
						line-height: 30px;

						&:last-child {
							margin-bottom: 0;
						}

						button {
							font-size: 18px;
							font-weight: 500;
							color: $theme-primary-color;
							position: relative;
							display: inline-block;
							margin-top: 10px;
							text-transform: capitalize;

							&:hover {
								background: none;
							}

							&:focus {
								outline: none;
								box-shadow: none;
							}

							&:before {
								position: absolute;
								left: 0;
								bottom: 0;
								width: 100%;
								height: 1px;
								content: "";
								background: $theme-primary-color;
							}
						}
					}
				}
			}
		}
	}

}

.quickview-dialog {
	z-index: 999999 !important;
}

.MuiDialog-paperWidthSm {
	max-width: 900px !important;
	z-index: 999;
}

.modal-body iframe {
	height: 500px;
	width: 800px;

	@media (max-width: 991px) {
		width: 500px;
	}

	@media (max-width: 600px) {
		width: 100%;
	}
}

.wpo-event-section-s2 {

	.wpo-section-title-s2{
		display: none;
	}

	&::after,
	&::before {
		display: none;
	}
}


/* 3.10 wpo-partners-section */

.wpo-partners-section {
	overflow: hidden;

	.slick-slider {
		margin: -15px;

		.slick-slide {
			padding: 15px;
		}
	}

	.container {
		position: relative;
	}

	.grid {
		text-align: center;
		border: 1px solid $border-color;
		min-height: 120px;
		margin-right: -1px;
		display: flex !important;
		align-items: center;
	}

	.grid img {
		width: auto;
		margin: 0 auto;
		display: inline-block;
	}

	.owl-nav {
		display: none;
	}
}


/* 3.11 wpo-blog-section */

.wpo-blog-section {
	padding-bottom: 90px;

	@media(max-width:767px) {
		padding-bottom: 60px;
	}

	.wpo-blog-item {
		margin-bottom: 30px;

		.wpo-blog-img {
			overflow: hidden;
			position: relative;

			img {
				width: 100%;
				-webkit-filter: grayscale(0);
				-moz-filter: grayscale(0);
				-o-filter: grayscale(0);
				-ms-filter: grayscale(0);
				filter: grayscale(0);
				transition: all .3s;
				transform: scale(1);
			}

			.thumb {
				position: absolute;
				left: 15px;
				top: 15px;
				padding: 7px 25px 6px;
				background: $white;
				color: #686868;
				font-size: 18px;
			}
		}

		&:hover {
			.wpo-blog-img {
				img {
					-webkit-filter: grayscale(100%);
					-moz-filter: grayscale(100%);
					-o-filter: grayscale(100%);
					-ms-filter: grayscale(100%);
					filter: grayscale(100%);
					transform: scale(1.2);
				}

			}
		}


		.wpo-blog-content {
			padding-top: 20px;

			ul {
				list-style: none;
				display: flex;
				margin-bottom: 15px;

				li {
					color: #686868;
					font-size: 17px;

					&:first-child {
						padding-right: 30px;
						position: relative;

						&:before {
							position: absolute;
							right: 10px;
							top: 50%;
							transform: translateY(-50%);
							width: 6px;
							height: 6px;
							content: "";
							background: #686868;
							border-radius: 50%;
						}
					}

					a {
						color: #686868;

						&:hover {
							color: $theme-primary-color;
						}
					}
				}
			}

			h2 {
				font-size: 30px;
				font-family: $base-font;
				margin-bottom: 10px;
				line-height: 36px;
				font-weight: 400;
				margin-top: 10px;

				@media(max-width:1200px) {
					font-size: 25px;
					margin-bottom: 10px;
				}

				a {
					color: $heading-color;

					&:hover {
						color: $theme-primary-color;
					}
				}
			}

			p {
				font-size: 18px;
				color: $text-color;
			}

			.read-more {
				text-decoration: underline;
				color: $theme-primary-color;
			}

		}
	}
	.wpo-contact-form-area {
		padding: 130px 50px;
		position: relative;
		z-index: 99;
		border-radius: 322px;

		@media(max-width:575px) {
			padding: 50px;
		}

		@media(max-width:450px) {
			padding: 50px 30px;
		}

		.wpo-section-title {
			margin-bottom: 20px;
		}

		.form-field {
			margin-bottom: 20px;
		}

		.errorMessage {
			font-size: 16px;
			color: red;
		}

		.form-control {
			width: 100%;
			height: 50px;
			border: 0;
			border-bottom: 1px solid #D9D9D9;
			background: transparent;
			border-radius: 0;
			color: #787878;
			padding-left: 0;
			font-size: 18px;
			margin-bottom: 3px;

			&:focus {
				outline: none;
				box-shadow: none;
			}
		}

		.form-control::-webkit-input-placeholder {
			/* Edge */
			color: #787878;
		}

		.form-control:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			color: #787878;
		}

		.form-control::placeholder {
			color: #787878;
		}


		select.form-control {
			color: #6c757d;
			-webkit-appearance: none;
			-ms-appearance: none;
			-o-appearance: none;
			appearance: none;
			-moz-appearance: none;
			background: transparent url(../../images/select-icon2.png) no-repeat calc(100% - 15px) center;
			position: relative;
		}


		.select.last {
			margin-bottom: 50px;
		}

		.submit-area {
			margin-top: 40px;
			text-align: center;
		}
	}
}